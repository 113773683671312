import React, { useRef } from "react";
import { Card, Figure, Container } from "react-bootstrap";
import Slider from "react-slick";
import {
  professionalChefs, pcTitle, pcCard, titlePosition,
  pcCardImg, chefTitle, position, pcCardBody,learnMore,
  lmText, lmFigure, pcCardBodyContent, sliderArrows,
  saPrev, saNext, prevIcon, nextIcon, pcImg
} from "./style.mod.scss";
// import { chefsData } from "./data";
import {  ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import getImagePath from "helpers/get-image-path";

const ProfessionalChefs = ({ data }) => {
  let _slider = useRef(null);

  const previous = () => {
    _slider.slickPrev();
  };

  const next = () => {
    _slider.slickNext();
  };

  const { ambassador, title } = data;

  const settings = {
    infinite: true,
    autoplay: true,
    className: "ambassadors",
    arrows: false,
    rows: 2,
    slidesToShow: 4,
    speed: 500,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows:3,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows:2,
          infinite: true,
        }
      },
    ]
  };

  return (
    <div className={professionalChefs}>
      <img className={pcImg}  src="https://imgur.com/22MzqRF.png"/>
      <Container>
        <h2 className={pcTitle}>{title}</h2>
        <Slider ref={c => (_slider = c)} {...settings}>
          {ambassador.map((chef) => {
            return( <div key={chef._key}>
              <a href={chef.link}>
                <Card className={pcCard}>
                  <Card.Img className={pcCardImg} srcSet={getImagePath(chef.image, true)} alt={chef.image?.alt} />
                  <Card.Body className={pcCardBody}>
                    <div className={pcCardBodyContent}>
                      <div className={titlePosition}>
                        <p className={chefTitle}>{chef.name}</p>
                        <p className={position}>{chef.position}</p>
                      </div>
                      <div className={learnMore}>
                        <p className={lmText}>Erfahre mehr über {chef.name}</p>
                        <Figure className={lmFigure}>
                          <Figure.Image
                            width={7}
                            height={10}
                            alt="171x180"
                            src="https://imgur.com/1HT58Kw.png"
                          />
                        </Figure>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </a>
            </div>
            );
          })}
        </Slider>
        <div className={sliderArrows}>
          <button className={saPrev} onClick={previous}>
            <span className={prevIcon}><ChevronLeft/></span>
          </button>
          <button className={saNext} onClick={next}>
            <span className={nextIcon}><ChevronRight/></span>
          </button>
        </div>
      </Container>
    </div>
  );
};

export default ProfessionalChefs;
