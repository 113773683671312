// extracted by mini-css-extract-plugin
export var professionalChefs = "style-mod--professional-chefs--yDoaE";
export var pcImg = "style-mod--pc-img--2CXA2";
export var pcTitle = "style-mod--pc-title--3o9Hb";
export var pcCard = "style-mod--pc-card--12Rtq";
export var pcCardImg = "style-mod--pc-card-img--2xdRj";
export var pcCardBody = "style-mod--pc-card-body--3xCI-";
export var pcCardBodyContent = "style-mod--pc-card-body-content--nyrBe";
export var titlePosition = "style-mod--title-position--nbp6g";
export var chefTitle = "style-mod--chef-title--1qTcB";
export var position = "style-mod--position--1r6Fl";
export var learnMore = "style-mod--learn-more--k6jTu";
export var lmText = "style-mod--lm-text--3OPFz";
export var lmFigure = "style-mod--lm-figure--2gohE";
export var sliderArrows = "style-mod--slider-arrows--2XIRN";
export var saPrev = "style-mod--sa-prev--32kcG";
export var saNext = "style-mod--sa-next--NAmS8";
export var nextIcon = "style-mod--next-icon--3lhit";
export var prevIcon = "style-mod--prev-icon--1kpWJ";